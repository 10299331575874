import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isUserLogedIn } from '@library/auth';

import DesktopTabletView from './desktopTableView.js';

import MobileView from './mobileView.js';
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';

import * as UserDashboardActions from '@redux/actions/UserDashboard';
import * as TimelineRedirectActions from '@redux/actions/TimelineRedirect';


function UserDashboard(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const activeTab = useSelector((state) => state.dashboard.activeTab);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const user = useSelector((state) => state.dashboard.user);

	const [isLoading, setIsLoading] = useState(false);

	const [myLearningTab, setMyLearningTab] = useState(true);
	const [evaluationTab, setEvaluationTab] = useState(false);
	const [profileTab, setProfileTab] = useState(false);
	const [settingTab, setSettingTab] = useState(false);

	useEffect(() => {
		checkLogin()
		handleTabSelect(activeTab)
	}, [activeTab])


	const checkLogin = async () => {
		let loginStatus = false
		try {
			const userLogedIn = await isUserLogedIn();
			if (userLogedIn) {
				loginStatus = true
			}
			else {
				if(localStorage.getItem('eMediat')){
					window.localStorage.removeItem('eMediat');
				}
				if(localStorage.getItem('eMediatt')){
					window.localStorage.removeItem('eMediatt');
				}
			}
		} catch (err) {
			console.log('Error coming while handling courseDetails page', err);
		}
		if (!loginStatus) {
			alert("Please login first")
			navigate('/login');
		}
	};



	const tabNavLinkClicked = () => {
		dispatch(TimelineRedirectActions.resetTimelineRedirectReducers())
	}

	const handleTabSelect = (eventKey) => {
		if (eventKey === 'myLearningTab') {
			setMyLearningTab(true);
			setEvaluationTab(false);
			setProfileTab(false);
			setSettingTab(false);
		} else if (eventKey === 'evaluationTab') {
			setMyLearningTab(false);
			setEvaluationTab(true);
			setProfileTab(false);
			setSettingTab(false);
		} else if (eventKey === 'profileTab') {
			setMyLearningTab(false);
			setEvaluationTab(false);
			setProfileTab(true);
			setSettingTab(false);
		} else if (eventKey === 'settingTab') {
			setMyLearningTab(false);
			setEvaluationTab(false);
			setProfileTab(false);
			setSettingTab(true);
		}
		dispatch(UserDashboardActions.updateActiveTab(eventKey));
	};

	return (
		<>
			<Navbar />

			{screenWidth > 576 ? (
				<DesktopTabletView
					isLoading={isLoading}
					activeTab={activeTab}
					handleTabSelect={handleTabSelect}
					tabNavLinkClicked={tabNavLinkClicked}
					myLearningTab={myLearningTab}
					evaluationTab={evaluationTab}
					profileTab={profileTab}
					settingTab={settingTab}
				/>
			) : (
				<MobileView
					isLoading={isLoading}
					activeTab={activeTab}
					handleTabSelect={handleTabSelect}
					tabNavLinkClicked={tabNavLinkClicked}
					myLearningTab={myLearningTab}
					evaluationTab={evaluationTab}
					profileTab={profileTab}
					settingTab={settingTab}
				/>
			)}

			<Footer />
		</>
	);
}

export default UserDashboard;
